<template>
    <div id="main-page">
        <div class="container">
          <app-bar
            title="Semua Promo"
            previous-link="/" />
          <div class="flex-row mb-2">
            <div class="d-flex gap-3">
              <label class="col-form-label">Filter : </label>
              <select class="form-select" v-model="filterCabang">
                <option selected value="">Pilih Lokasi</option>
                <option v-for="cabang in subDivisions" :key="cabang.id" :value="cabang.id">{{ cabang.nama }}</option>
              </select>
            </div>
          </div>
          <Loading v-if="loading.promo" />
          <div v-else-if="listPromo.length" class="row">
            <div v-for="promo in listPromo" :key="promo.id_promo" class="col-12 col-md-3 pt-2 pb-2">
              <div class="card" @click="handleOnClick(promo)" role="button">
                <div class="card-body p-0">
                  <div class="container-desc d-flex flex-column">
                    <div class="border-radius-10">
                        <img class="icon-links" :src="promo.banner">
                    </div>
                    <div class="description p-3 pb-1">
                      <h5 class="card-title">
                        {{ promo.nama }}
                      </h5>
                      <span class="text-muted small">Harga Promo : Rp.
                        {{ parseInt(promo.harga).toLocaleString('id-ID') }}
                      </span>
                    </div>
                    <div class="desc-link p-2 ps-3">
                      Berlaku di <span v-for="(cabang_berlaku, index) in promo.cabang_berlaku" :key="cabang_berlaku.id">
                        {{ cabang_berlaku.cabang }} {{ (promo.cabang_berlaku.length - 1 !== index) ? ', ':'' }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            class="not-found d-flex flex-column text-center pb-5 pt-5">
            <div class="bd-highlight">
                <img src="@/assets/icon/not-found.png" alt="">
            </div>
            <div class="bd-highlight">
                <p class="text-title-active fm-poppins fst-normal fw-600 fs-24 lh-36">Tidak Ada Hasil</p>
            </div>
            <div class="bd-highlight">
                <p class="text-body-light fm-nunito fst-normal fw-400 fs-16 lh-21">Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan penelusuran Anda.</p>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import { nextTick, onMounted, ref, reactive, watch } from 'vue' // computed
import { title } from '@/core/page'
import { analytics } from '@/core/firebase-messaging'
import axios from '@/plugins/axios'
import { subDivisions, getSubDivisions, loading as loadingCabang, selectedPromo } from '@/core/subDivisions'
import Loading from '@/components/utility/Loading'
import { useRouter } from 'vue-router'

export default {
  components: {
    AppBar, Loading
  },
  setup () {
    const filterCabang = ref('')
    const loading = reactive({
      promo: false
    })
    const listPromo = ref([])
    const router = useRouter()
    const auth = localStorage.getItem('token')

    const getPromo = async (payload) => {
      try {
        loading.promo = true
        const { data } = await axios.get('app/promo', {
          params: payload
        })
        listPromo.value = data.data
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        loading.promo = false
      }
    }

    onMounted(async () => {
      await getSubDivisions()
      await getPromo()

      nextTick(() => {
        title.value = 'Semua Promo'
      })
    })

    watch(filterCabang, async (newVal) => {
      await getPromo({
        cabang: newVal
      })
    })

    const handleOnClick = (promo) => {
      analytics.logEvent('promo_clicked')

      if (!auth) {
        analytics.logEvent('need_to_login_first')
        router.push({ name: 'Login' })
      } else {
        selectedPromo.value = promo
        router.push({
          name: 'SelectBranch',
          query: {
            redirectTo: '/keranjang'
          }
        })
      }
    }

    return {
      loading,
      handleOnClick,
      listPromo,
      loadingCabang,
      subDivisions,
      filterCabang
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-links {
  width: 100%;
  height: auto;
}

.card {
  border-radius: 20px;
  height: 100%;
  -webkit-box-shadow: -5px 5px 20px -15px rgba(0,0,0,0.75);
  -moz-box-shadow: -5px 5px 20px -15px rgba(0,0,0,0.75);
  box-shadow: -5px 5px 20px -15px rgba(0,0,0,0.75);

  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .card-title {
    height: fit-content;
    margin-bottom: 5px;
  }

  .desc-link {
    background: #efefef;
    border-radius: 0 0 20px 20px;
    color: #1c8e99;
  }
}

.form-control, .input-group-text {
    border-radius: 8px;
}

.form-select {
  width: auto;
}

</style>
